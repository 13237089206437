import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const TokensLookup = makeShortcode("TokensLookup");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Tabs mdxType="Tabs">
  <Tab title="Global" mdxType="Tab">
    <TokensLookup type="modifiers" title="Border" tagline="Border tokens determine the stroke width and radius of different elements." brand="Global" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Button" tagline="The modifier button tokens allow brands to modify the default button radius and/or border width." brand="Global" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Footer" tagline="Footer tokens set the background and text colour for footer elements." brand="Global" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Focus" tagline="Focus tokens determine the appearance of the focus box." brand="Global" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Header" tagline="Header tokens set the background and text colour for header elements." brand="Global" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Opacity" tagline="Opacity tokens set the opacity of different elements." brand="Global" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Overlay" tagline="Overlay tokens doteremine the colour and opacity of overlays like modals." brand="Global" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Transition" tagline="Transition tokens determine how quickly different elements animate in and out." brand="Global" removeBottomBorder mdxType="TokensLookup" />
  </Tab>
  <Tab title="Sainsbury's" mdxType="Tab">
    <TokensLookup type="modifiers" title="Border" tagline="Border tokens determine the stroke width and radius of different elements." brand="Sainsbury's" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Button" tagline="The modifier button tokens allow brands to modify the default button radius and/or border width." brand="Sainsbury's" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Footer" tagline="Footer tokens set the background and text colour for footer elements." brand="Sainsbury's" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Focus" tagline="Focus tokens determine the appearance of the focus box." brand="Sainsbury's" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Header" tagline="Header tokens set the background and text colour for header elements." brand="Sainsbury's" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Opacity" tagline="Opacity tokens set the opacity of different elements." brand="Sainsbury's" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Overlay" tagline="Overlay tokens doteremine the colour and opacity of overlays like modals." brand="Sainsbury's" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Transition" tagline="Transition tokens determine how quickly different elements animate in and out." brand="Sainsbury's" removeBottomBorder mdxType="TokensLookup" />
  </Tab>
  <Tab title="Argos" mdxType="Tab">
    <TokensLookup type="modifiers" title="Border" tagline="Border tokens determine the stroke width and radius of different elements." brand="Argos" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Button" tagline="The modifier button tokens allow brands to modify the default button radius and/or border width." brand="Argos" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Footer" tagline="Footer tokens set the background and text colour for footer elements." brand="Argos" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Focus" tagline="Focus tokens determine the appearance of the focus box." brand="Argos" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Header" tagline="Header tokens set the background and text colour for header elements." brand="Argos" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Opacity" tagline="Opacity tokens set the opacity of different elements." brand="Argos" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Overlay" tagline="Overlay tokens doteremine the colour and opacity of overlays like modals." brand="Argos" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Transition" tagline="Transition tokens determine how quickly different elements animate in and out." brand="Argos" removeBottomBorder mdxType="TokensLookup" />
  </Tab>
  <Tab title="Tu" mdxType="Tab">
    <TokensLookup type="modifiers" title="Border" tagline="Border tokens determine the stroke width and radius of different elements." brand="Tu" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Button" tagline="The modifier button tokens allow brands to modify the default button radius and/or border width." brand="Tu" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Footer" tagline="Footer tokens set the background and text colour for footer elements." brand="Tu" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Focus" tagline="Focus tokens determine the appearance of the focus box." brand="Tu" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Header" tagline="Header tokens set the background and text colour for header elements." brand="Tu" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Opacity" tagline="Opacity tokens set the opacity of different elements." brand="Tu" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Overlay" tagline="Overlay tokens doteremine the colour and opacity of overlays like modals." brand="Tu" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Transition" tagline="Transition tokens determine how quickly different elements animate in and out." brand="Tu" removeBottomBorder mdxType="TokensLookup" />
  </Tab>
  <Tab title="Habitat" mdxType="Tab">
    <TokensLookup type="modifiers" title="Border" tagline="Border tokens determine the stroke width and radius of different elements." brand="Habitat" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Button" tagline="The modifier button tokens allow brands to modify the default button radius and/or border width." brand="Habitat" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Footer" tagline="Footer tokens set the background and text colour for footer elements." brand="Habitat" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Focus" tagline="Focus tokens determine the appearance of the focus box." brand="Habitat" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Header" tagline="Header tokens set the background and text colour for header elements." brand="Habitat" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Opacity" tagline="Opacity tokens set the opacity of different elements." brand="Habitat" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Overlay" tagline="Overlay tokens doteremine the colour and opacity of overlays like modals." brand="Habitat" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Transition" tagline="Transition tokens determine how quickly different elements animate in and out." brand="Habitat" removeBottomBorder mdxType="TokensLookup" />
  </Tab>
  <Tab title="Nectar" mdxType="Tab">
    <TokensLookup type="modifiers" title="Border" tagline="Border tokens determine the stroke width and radius of different elements." brand="Nectar" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Button" tagline="The modifier button tokens allow brands to modify the default button radius and/or border width." brand="Nectar" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Footer" tagline="Footer tokens set the background and text colour for footer elements." brand="Nectar" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Focus" tagline="Focus tokens determine the appearance of the focus box." brand="Nectar" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Header" tagline="Header tokens set the background and text colour for header elements." brand="Nectar" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Opacity" tagline="Opacity tokens set the opacity of different elements." brand="Nectar" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Overlay" tagline="Overlay tokens doteremine the colour and opacity of overlays like modals." brand="Nectar" mdxType="TokensLookup" />
    <TokensLookup type="modifiers" title="Transition" tagline="Transition tokens determine how quickly different elements animate in and out." brand="Nectar" removeBottomBorder mdxType="TokensLookup" />
  </Tab>
    </Tabs>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions you have or help you with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      